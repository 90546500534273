import Jouer from "./components/Jouer";
import Tinder from "./components/Tinder";
import Drawing from "./components/Drawing";
import CookieUtil from "./utils/Cookie";
import Score from "./components/Score";
import {initIntersection} from "./utils/utils";
import gsap from "gsap";
import ScrollTrigger from "./utils/ScrollTrigger.min";
import * as Klaro from "klaro/dist/klaro-no-css";

gsap.registerPlugin(ScrollTrigger, SplitText);
export default class App {
  constructor() {
    this.lenis;
    console.log("%cGenesii with ❤", "color: #9ed6a8; padding: 5px 0px 1px; border-bottom:2px solid #9ed6a8;");
    this.init();
  }

  init() {

    this.lenis = new Lenis({
      syncTouchLerp: 0.075,
      touchInertiaMultiplier: 35,
      // wheelEventsTarget: document.body
    })
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    }
    this.lenis.scrollTo(0)

    initIntersection(".js-observer", .2)

    document.getElementById('like').click();

    //JEU ALEATOIRE
    if(CookieUtil.get("gameLaunched") && $(CookieUtil.get("gameLaunched")).length > 0) {
      //Decommenter pour réactiver aléatoire
      //$(CookieUtil.get("gameLaunched")).remove();
    }
    //Decommenter pour réactiver aléatoire
    //const randomIndex = Math.floor(Math.random() * $(`.section3`).length);
    const randomIndex = 0;
    $($(`.section3`)[randomIndex]).css('display', 'block');
    $('.btn-like').attr('href', '#'+$($(`.section3`)[randomIndex]).attr('id'))

    function overlap(div1, div2) {
      // Div 1 data
      let d1Offset = div1.offset();
      let d1Height = div1.outerHeight(true);
      let d1Width = div1.outerWidth(true);
      let d1Top = d1Offset.top + d1Height;
      let d1Left = d1Offset.left + d1Width;

      let d2Offset = div2.offset();
      let d2Height = div2.outerHeight(true);
      let d2Width = div2.outerWidth(true);
      let d2Top = d2Offset.top + d2Height;
      let d2Left = d2Offset.left + d2Width;
      return !(d1Top < d2Offset.top || d1Offset.top > d2Top || d1Left < d2Offset.left || d1Offset.left > d2Left);
    }

    this.lenis.on('scroll', (e) => {

      if (window.scrollY > 100) {
        $("header.header").addClass("disabled")

      } else {
        $("header.header").removeClass("disabled")
      }
    })
    gsap.ticker.add((time) => {
      this.lenis.raf(time * 1000)
    })
    gsap.ticker.lagSmoothing(0)

    setTimeout(() => {
      $("header.header").addClass("animated")
    }, 555)
    let jouer = new Jouer();
    if($('.tinder').length > 0) {
      let tinder = new Tinder(ScrollTrigger, this.lenis);
    }
    this.refreshGame();
    this.checkIfMobile();
    this.initCookie();
    this.checkSafari();

    if($('#drawingCanvas').length > 0) {
      const drawingApp = new Drawing('drawingCanvas', ScrollTrigger, this.lenis);
    }
  }

  refreshGame() {
    $(".block-refresh a").click(function (e) {
      e.preventDefault();
      var redirectToURL = '#futur-metier';
      window.location.href = redirectToURL;
      window.location.reload(true);
    })

    let $context = this;
    $(".btn-like").on("click", (e) => {
      e.preventDefault();
      $context.lenis.scrollTo($(".btn-like").attr('href'));
    })
  }

  checkIfMobile() {
    window.mobileCheck = function () {
      let check = false;
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    }
    if (mobileCheck()) {
      $("body").addClass("mobile")
    }
  }

  initCookie() {
    const config = {
      default: true,
      htmlTexts: true,
      acceptAll: true,
      translations: {
        en: {
          decline: "Decline",
          save: "Save",
          ok: "Accept",
          acceptAll: "Accept",
          acceptSelected: "Save my choices",
          close: "Close",
          consentNotice: {
            changeDescription: "There were changes since your last visit, please renew your consent.",
            description: "<strong>Roll in the cookies</strong> <br><br> Our site and its partners use cookies to measure the audience and the performance of the site, and thus improve the user experience. <br><br> You can change your choices at any time in the \"Manage Cookies\" section of our site. <br>" +
              "<br><br>For more information, please see our cookie use policy. ",
            learnMore: "Manage cookies",
          },

          consentModal: {
            title: 'Cookie management',
            description: "Our site and its partners use cookies to measure the audience and the performance of the site, and thus improve the user experience." +
              "<br> <br> Choose which categories of cookies you want us to collect. This choice will be saved for 6 months. Note that blocking certain types of cookies may degrade your browsing experience. <br><br>" +
              "For more information, please see our cookie use policy. <br><br><strong>Technical</strong></br></br>" +
              "These cookies enable the website to provide enhanced functionality, personalisation, or to carry out the actions you intiate (e.g. remembering your cookies choices) and cannot be disabled as they do not require your consent. They may be set by us or by third party providers whose services we have added to our pages."
          },
          inlineTracker: {
            description: 'Example of an inline tracking script',
          },
          externalTracker: {
            description: 'Example of an external tracking script',
          },
        },
        fr: {
          consentNotice: {
            description: "Notre site utilise des cookies à des fins de suivi statistique et marketing, pour observer et mieux comprendre l'utilisation qui en est faite. Vous avez le choix d'accepter ou non.",
          },
        }
      },
      purposes: {
        advertising: {
          description: "",
          title: "Advertising"
        },
        functional: {
          description: "These services are essential for the correct functioning of this website. You cannot disable them here as the service would not work correctly otherwise.\n",
          title: "Service Provision"
        },
        marketing: {
          description: "The purpose of these cookies is to analyze traffic and use made of the site, to better understand how it is used and to be able to improve your browsing experience. These cookies may in particular process data relating to the pages visited, the durations during which the pages are visited, the browser you use to visit these pages, etc. The use and deposit of these cookies is subject to obtaining your consent.",
          title: "Marketing"
        },
        // performance: {
        //   description: "These services process personal information to optimize the service that this website offers.\n",
        //   title: "Performance Optimization"
        // }
      },

      services: [
        {
          name: 'google-tag-manager',
          required: true,
          cookies: [
            /^_ga(_.*)?/ // we delete the Google Analytics cookies if the user declines its use
          ],
          purposes: ['marketing'],
          onAccept: `
                // we notify the tag manager about all services that were accepted. You can define
                // a custom event in GTM to load the service if consent was given.
                for(let k of Object.keys(opts.consents)){
                    if (opts.consents[k]){
                        let eventName = 'klaro-'+k+'-accepted'
                        dataLayer.push({'event': eventName})
                    }
                }
            `,
          onDecline: `
                gtag('consent', 'update', {'ad_storage': 'denied', 'analytics_storage': 'denied', 'ad_user_data': 'denied', 'ad_personalization': 'denied'})
                gtag('set', 'ads_data_redaction', false)
          `,
          onInit: `
                // initialization code here (will be executed only once per page-load)
                window.dataLayer = window.dataLayer || [];
                window.gtag = function(){dataLayer.push(arguments)}
                gtag('consent', 'default', {'ad_storage': 'granted', 'analytics_storage': 'granted', 'ad_user_data': 'granted', 'ad_personalization': 'granted'})
                gtag('set', 'ads_data_redaction', true)
            `,
        },
      ]

    };

    if(!this.checkModeIframe() && CookieUtil.get("mode") != "iframe") {
      window.klaro = Klaro;
      window.klaroConfig = config;
      Klaro.setup(config);
      klaro.language("fr")

      $('.js').click(function (e) {
        e.preventDefault();
        klaro.show()
      })
    } else {
      CookieUtil.set('mode', 'iframe', 7);
    }
  }
  checkModeIframe() {
    // Récupère la chaîne de requête de l'URL
    var queryString = window.location.search;

    // Utilise une expression régulière pour vérifier la présence du paramètre `mode=iframe`
    var regex = /[?&]mode=iframe/;

    // Teste la chaîne de requête avec l'expression régulière
    if (regex.test(queryString)) {
      return true;
    } else {
      return false;
    }
  }

  checkSafari() {
    function appHeight() {
      const doc = document.documentElement
      doc.style.setProperty('--vh', (window.innerHeight*.01) + 'px');
    }

    window.addEventListener('resize', appHeight);
    appHeight();

    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      document.querySelector(".intro").classList.add("is-safari");
    } else {
    }
  }
}
